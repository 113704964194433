import React from 'react'

export const Header = () => {
    return (
        <div id="nav_bar">
			<nav className="navbar navbar-expand-lg navbar-light bg-light">
				<div className="container">
					<a className="navbar-brand" href="/">UBONACCESSORIES</a>
					<button
						className="navbar-toggler"
						type="button"
						data-toggle="collapse"
						data-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon"></span>
					</button>

					<div className="collapse navbar-collapse" id="navbarSupportedContent">
						<ul className="navbar-nav ml-auto">
							<li className="nav-item active">
								<a className="nav-link mx-lg-2" href="/">HOME</a>
							</li>
							<li className="nav-item mx-lg-2">
								<a className="nav-link" href="/products">PRODUCTS</a>
							</li>
							<li className="nav-item mx-lg-2">
								<a className="nav-link" href="/cart">CART</a>
							</li>
							{/* <li className="nav-item mr-lg-5">
								<a className="nav-link" href="/cart"
									><i className="fa fa-shopping-cart"
										><span className="badge"><sup>{cartQty}</sup></span></i
									></a
								>
							</li> */}
						</ul>
					</div>
				</div>
			</nav>
		</div>
    )
}
