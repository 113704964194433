import React from 'react'

export const Footer = () => {
    return (
        <footer className="page-footer grey-bg">
            <div className="container">
                <div className="footer-copyright text-center py-3">
                    © 2021 UBONACCESSORIES
                </div>
            </div>
        </footer>
    )
}
