import React, {useEffect, useState} from 'react'
import { isEmpty, isNil } from 'lodash'
import { CategoryCard } from "../component/CategoryCard";
import { ProductsList } from "../component/ProductsList";
import { getCategory, getProducts } from "../utils/api";

export const Home = () => {
    const [products, setProducts] = useState()
    const [categories, setCategories] = useState()
    useEffect(() => {
        const fetch = async () => {
            const {categoryData} =  await getCategory()
            const {productsData} = await getProducts(1)
            setProducts(productsData)
            setCategories(categoryData)
        }
        fetch()
    }, [])

    return (
        <>
        {!isNil(categories) || !isNil(products) ? 
            <>
              {!isEmpty(categories) ? <CategoryCard categories={categories} /> : <h3 className="text-center py-5">Category Not Available</h3>}
              {!isEmpty(products) ? <> <ProductsList  products={products} title={'NEW PRODUCTS'}/>
              <div className="text-center pb-3">
                <a class="btn dark-btn" href="/products" role="button">VIEW ALL</a>
              </div> </> : <div class="page-wrap d-flex flex-row align-items-center">
                              <div class="container">
                                  <div class="row justify-content-center">
                                      <div class="col-md-12 text-center">
                                          <span class="display-1 d-block">No Products Found</span>
                                          <a href="/" class="btn btn-link">Back to Home</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
              }
            </>
          : 
          <div className='py-5 page-wrap'>
            <div className='text-center mx-auto py-5 my-5'>
                <div class="spinner-border" style={{width: "5rem", height: "5rem"}} role="status"></div>
            </div>
          </div>
        }
        </>
    )
}
