import React, { useState, useEffect } from 'react'
import { debounce, find } from 'lodash'
import { notification } from 'antd';
let cart = []
export const ProductsList = ({products, title}) => {
    const [pre, setPre] = useState({})

    useEffect(() => {
        cart = JSON.parse(localStorage.getItem('cart')) ?? []
    }, [pre])

    const qtyChange = debounce((e, productName) => {
        const input = +(e?.target?.value)
        if(input < 1) return
        if(pre?.name === productName && pre?.qty === input) return
        const productAdded = {
            name: productName,
            qty: e.target.value
        }

        const inCart =  find(cart, { 'name': productName });
        if(!inCart) {
            cart.push({
                name: productName,
                qty: e.target.value
            })
            localStorage.setItem('cart', JSON.stringify(cart))
            setPre(productAdded)
            notification.success({
                message: `${productName} added to cart`,
                duration: 2,
            });
            return
        }   

        if(+(inCart.qty) !== input) {
            const newCart = cart.filter((item) => {
                if(item.name !== productName) {
                    return item
                }
            })
            newCart.push({
                name: productName,
                qty: e.target.value
            })
            localStorage.setItem('cart', JSON.stringify(newCart))
            setPre(productAdded)
            notification.success({
                message: `${productName} Quantity Changed`,
                duration: 5,
            });
            return
        }

        // notification.warning({
        //     message: `Already in cart`,
        //     description: `${productName} Already in cart with quantity ${e.target?.value}. If you want to change quantity please enter different quantity`,
        //     duration: 2,
        // });
          
    }, 1000)
    const placeholderImage = 'https://via.placeholder.com/150x150.png?text=No%20Image'
    return (
        <div className="blog my-2" id="products">
            <div className="container-fluid">
                <div className="text-center mt-5 mb-4">
                    <p className="title-sm">{title ? title : ''}</p>
                </div>
                <div className="row m-sm-auto">
                    {products.map(product => (
                        <div className="col-md-4 pb-4" key={product.slug}>
                            <div className="card card-body">
                                <div className="media align-items-center align-items-lg-start text-center text-lg-left flex-column flex-lg-row">
                                    <div className="mr-2 mb-3 mb-lg-0">
                                        <img src={product?.images[0]?.src ?? placeholderImage} width="150" height="150" alt="" />
                                    </div>
                                    <div className="media-body">
                                        {/* <h6 className="media-title font-weight-semibold"> <a href={`/product/${product.slug}`} data-abc="true">{product.name}</a></h6> */}
                                        <h6 className="media-title font-weight-semibold">{product.name}</h6>
                                        <ul className="list-inline list-inline-dotted mb-3 mb-lg-2">
                                            <div className="col-xs-2">
                                                <label htmlFor="ex1">Quantity</label>
                                                <input className="form-control" type="number" min='0' placeholder='0' name="qty" onChange={e => qtyChange(e, product.name)} />
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    ))}
                </div>
            </div>
            <a className="btn dark-btn" href="/cart" role="button" style={{position: "fixed", bottom: '20px', right: '20px'}}><i className="fa fa-shopping-cart fa-lg"></i></a>
        </div>
    )
}
