import React from 'react'

export const CategoryCard = ({categories}) => {
    return (
        <div className="blog my-2" id="products">
            <div className="container-fluid">
                <div className="text-center mt-5 mb-4">
                    <p className="title-sm">SHOP BY CATEGORY</p>
                </div>
                <div className="row m-sm-auto">
                    {categories.map(categorie => (
                        categorie.name === "Uncategorized" ? null :
                        <div className="col-md-4 pb-4" key={categorie.id}>
                            <a href={`products?categorie=${categorie.id}`} style={{textDecoration: 'none'}}>
                                <div className="category dark-btn text-center">
                                    <h2 className='py-4 text-white'>{categorie.name}</h2>
                                </div>
                            </a>
                        </div> 
                    ) )}
                </div>
            </div>
        </div>
    )
}
