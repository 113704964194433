import React from 'react'
import { Switch ,BrowserRouter as Router, Route } from "react-router-dom";
import { Header } from "./component/Header";
import { Footer } from "./component/Footer";
import { Home } from "./pages/Home";
import { Products } from "./pages/Products";
import { Cart } from "./pages/Cart";
import Page404 from "./pages/Page404";
import './App.css';

function App() {
  
  return (
    <div>
      <Router>
        <div className="App">
          <Header />
          <Switch>
            <Route exact path='/' render={props => (
              <Home />      
            )} />
            <Route path='/products' component={Products} />
            <Route path='/cart' component={Cart} />
            <Route path='' component={Page404} />
          </Switch>
          <Footer />
        </div>
      </Router>
    </div>
  );
}

export default App;
