import React, {useEffect, useState} from 'react'
import { Pagination } from 'antd';
import { getProducts } from "../utils/api";
import { isEmpty, isNil } from 'lodash';
import { ProductsList } from "../component/ProductsList";
import "antd/dist/antd.css";
const queryString = require('query-string')

export const Products = (props) => {
    const query = queryString.parse(props.location.search)
    const categorieId = query.categorie ?? false
    const [products, setProducts] = useState()
    const [current, setCurrent] = useState(1)
    const [totalPages, setTotalPages] = useState()

    useEffect(() => {
        const fetch = async () => {
            const {productsData, count} = categorieId ? await getProducts(current, categorieId) : await getProducts(current)
            setProducts(productsData)
            setTotalPages(count)
        }
        fetch()
    }, [current, categorieId])

    const onChange = page => {
        setCurrent(page);
        setProducts()
    };

    return (
        <div>
            { !isNil(products) ?
                !isEmpty(products) ?
                    <>
                        <ProductsList  products={products} title={categorieId ? '' :'ALL PRODUCTS'}/>
                        <div className="text-center pb-4">
                            <Pagination current={current} onChange={onChange} defaultPageSize={9} total={totalPages} />
                        </div>
                    </>
                :
                <div class="page-wrap d-flex flex-row align-items-center">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-md-12 text-center">
                                <span class="display-4 d-block">No Products Found</span>
                                <a href="/" class="btn btn-link">Back to Home</a>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className='py-5 page-wrap'>
                    <div className='text-center mx-auto py-5 my-5'>
                        <div className="spinner-border" style={{width: "5rem", height: "5rem"}} role="status">
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
