import axios from 'axios';
require('dotenv').config()

const auth_Config = {
    auth: {
        username: process.env.REACT_APP_CONSUMERKEY,
        password: process.env.REACT_APP_CONSUMERSECRET
    },
    config: {
        headers: {
          'Access-Control-Allow-Origin': '*',
        }
    }
}

const url = "https://ubonaccessories.com/wp-json/wc/v3/products"

const getProducts = async (page, categoryId) => {
    try {
        const res = await axios({
            method: 'get',
            url: categoryId ? `${url}?category=${categoryId}&per_page=9&page=${page}` : `${url}?per_page=9&page=${page}`,
            ...auth_Config
        })
        const productsData = res.data.filter(item => item.stock_status === "instock")
        // const productsData = res.data
        const count = +(res.headers["x-wp-total"])
        const pages = +(res.headers["x-wp-totalpages"])
        return {productsData, pages, count}
    } catch (error) {
        console.log(error)
    }
}

const getCategory = async () => {
    try {
        const res = await axios({
            method: 'get',
            url: `${url}/categories`,
            ...auth_Config
        })
        const categoryData = res.data
        return {categoryData}
    } catch (error) {
        console.log(error)
    }
}

const getProduct = async (product) => {
    try {
        const res = await axios({
            method: 'get',
            url: `${url}/?slug=${product}`,
            ...auth_Config
        })
        const productData = res.data
        return {productData}
    } catch (error) {
        
    }
}

export {getProducts, getProduct, getCategory}
