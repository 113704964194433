import React, { useEffect, useState } from 'react'
import {debounce, isEmpty, isNull, clone} from "lodash";
import "antd/dist/antd.css";
import { InputNumber } from 'antd';

export const Cart = () => {
    const [cart, setCart] = useState([])
    const [pre, setPre] = useState({})
    useEffect(() => {
        const cartProduct = JSON.parse(localStorage.getItem('cart')) ?? []
        setCart(cartProduct)
    }, [pre])

    const onDelete = (productName) => {
        const newCart = cart.filter(item => item.name != productName)
        localStorage.setItem('cart', JSON.stringify(newCart))
        if(!isEmpty(productName)) {
            setPre({})
        }
        return
    }

    const changeQty = debounce((value, productName) => {
        if(!isNull(value)) {
            const cartClone = clone(cart)
            const newCart = cartClone.map(item => {
                if(item.name === productName) {
                    return {name: productName, qty: value}
                }
                return item
            })
            localStorage.setItem('cart', JSON.stringify(newCart))
        }
    },500)

    const placeOrder = (number) => {
        const productInCart = cart
        let message =  'Hello,%0AI%20want%20to%20order%0A'
        productInCart.forEach(item => {
            message +=`%0AProduct:%20${(item.name).replace(" ", "%20")}%0AQuantity:%20${item.qty}%0A`
        })
        message += `%0AThank%20You`
        // window.open(`https://wa.me/919326793932/?text=${message}`)
        window.open(`https://wa.me/91${number}/?text=${message}`)
    }

    const emptyCart = () => {
        localStorage.removeItem("cart")
        setPre({})
    }
    return (
        <>
            
            {!isEmpty(cart) &&
            <div className='page-wrap'>
                <div className="p-5" style={{background: '#e9ecef'}}>
                    <h1 className="text-center">CART</h1>
                </div>
                <div className="row pt-4">
                    <div className="col-md-4 col-sm-12"></div>
                    <div className="col-8 mx-auto">
                    {cart.map(product => (
                        <div className="row py-3">
                            <div className="col-md-4 col-sm-12">
                                <h6>{product.name}</h6>
                            </div>
                            <div className="col-xs-6 text-md-right mt-1"> 
                            {/* Qty: col-md-5 col-sm-6 text-md-right mt-1  */}
                                <InputNumber min={1} max={100000} defaultValue={product.qty} onChange={value => changeQty(value,product.name)} />
                            </div>
                            <div className="col-xs-6">
                                <button className="btn dark-btn" onClick={() => onDelete(product.name)}>REMOVE</button>
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
                <div className="container py-5 cart-btn">
                    <div className="col-lg-5 col-sm-12 d-inline">
                        <button className="btn btn-lg dark-btn" onClick={() => placeOrder(9823882457)}><i className="fa fa-whatsapp" aria-hidden="true"></i> Place Order (Mumbai) </button>
                    </div>
                    <div className="col-lg-5 col-sm-12 d-inline">
                        <button className="btn btn-lg dark-btn" onClick={() => placeOrder(9343599116)}><i className="fa fa-whatsapp" aria-hidden="true"></i> Place Order (Nagpur)</button>
                    </div>
                    <div className="col-lg-5 col-sm-12 d-inline">
                        <button className="btn btn-lg dark-btn" onClick={emptyCart}>Empty Cart</button>
                    </div>
                </div> 
            </div>}
            {isEmpty(cart) && <div class="page-wrap d-flex flex-row align-items-center">
                                <div class="container">
                                    <div class="row justify-content-center">
                                        <div class="col-md-12 text-center">
                                            <span class="display-4 d-block">Cart Empty</span>
                                            <a href="/products" class="btn btn-link">Add Some Products</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
            }
        </>

        
    )
}
